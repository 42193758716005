















import Avatar from '@/components/Avatar.vue'
import { S3_URL } from '@/common/constants'
import { mapActions } from 'vuex'

export default {
  name: 'RequestHeader',

  components: {
    Avatar
  },

  props: {
    profile: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      defaultAvatarUrl: `${S3_URL}/default_avatar.png`
    }
  },

  methods: {
    ...mapActions('profiles', [
      'updateBusinessProfile'
    ]),
    ...mapActions('pgt', [
      'getPgtProfile'
    ]),
    async handleDeleteAvatar () {
      const updatedProfile = { ...this.profile, profile_data: this.profile.business_profile }

      updatedProfile.avatar_url = this.defaultAvatarUrl

      try {
        this.saving = true
        await this.updateBusinessProfile(updatedProfile)
        this.$toast.success('Аватар удалён')
        await this.getPgtProfile(this.$route.params.profileId)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async handleUploadedAvatar (url: string) {
      const updatedProfile = { ...this.profile, profile_data: this.profile.business_profile }

      updatedProfile.avatar_url = url

      try {
        this.saving = true
        await this.updateBusinessProfile(updatedProfile)
        this.$toast.success('Аватар обновлен')
        await this.getPgtProfile(this.$route.params.profileId)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    }
  }
}
