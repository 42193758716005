
















































import Vue from 'vue'
import ModalAddImage from './ModalAddImage.vue'
import { S3_URL } from '@/common/constants'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default Vue.extend({
  name: 'Avatar',

  components: {
    CoolLightBox,
    ModalAddImage
  },

  props: {
    imageUrl: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      defaultAvatarUrl: `${S3_URL}/default_avatar.png`,
      galleryIndex: null,
      modalShow: false,
      isOpen: false
    }
  },

  methods: {
    async onImageUploaded (url: string) {
      this.$emit('uploaded', url)
      this.modalShow = false
    }
  }
})
