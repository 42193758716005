
















































import Vue from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import ProfileHeader from '@/components/PGT/ProfileHeader.vue'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import ModalPGTToBP from '@/components/PGT/ModalPGTToBP.vue'

export default Vue.extend({
  name: 'PGTProfile',

  components: {
    ProfileHeader,
    Breadcrumb,
    ModalPGTToBP
  },

  data () {
    return {
      isShownModal: false
    }
  },

  computed: {
    ...mapState('pgt', [
      'pgtProfile'
    ]),

    breadcrumbItems () {
      return [
        // {
        //   text: 'Каталоги',
        //   to: { name: 'pgt-catalogs', params: { profileId: this.$route.params.profileId } }
        // },
        {
          text: this.$route.meta.title,
          active: true
        },
        {
          text: this.pgtProfile.username,
          to: { name: 'pgt-main', params: { profileId: this.$route.params.profileId } }
        }
      ]
    }
  },

  watch: {
    $route () {
      this.setBreadcrumbItems(this.breadcrumbItems)
    }
  },

  beforeRouteUpdate (to, from, next) {
    // this.setBreadcrumbItems([])
    next()
  },

  async created () {
    this.getBusinessCategories()
    await this.getProfileDetails()
    this.setBreadcrumbItems(this.breadcrumbItems)
  },

  beforeDestroy () {
    this.resetProfile()
    this.setBreadcrumbItems([])
  },

  methods: {
    ...mapActions('pgt', [
      'getPgtProfile'
    ]),

    ...mapActions('common', [
      'getBusinessCategories',
      'setBreadcrumbItems'
    ]),

    ...mapMutations('profiles', [
      'resetProfile'
    ]),

    async getProfileDetails () {
      try {
        this.loading = true
        await this.getPgtProfile(this.$route.params.profileId)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }
  }
})
