






import Vue from 'vue'
import {
  mapState
} from 'vuex'

export default Vue.extend({
  name: 'Breadcrumb',

  computed: {
    ...mapState('common', [
      'breadcrumbItems'
    ]),

    routeClass () {
      return `page-${this.$route.name}`
    },

    localBreadcrumbItems () {
      if (this.breadcrumbItems.length) return this.breadcrumbItems

      return [
        {
          text: 'Главная',
          to: { name: 'home' }
        },
        {
          text: this.$route.meta.title,
          active: true
        }
      ]
    }
  }
})
