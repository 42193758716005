

































import Vue, { PropType } from 'vue'
import Loading from '@/components/Loading/index.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'modalPGTToBP',

  components: {
    Loading
  },

  props: {
    username: {
      type: String as PropType<string>,
      default: ''
    },
    id: {
      type: String as PropType<string>,
      default: ''
    }
  },

  data () {
    return {
      showed: true,
      loading: false
    }
  },

  methods: {
    ...mapActions('pgt', [
      'removePGTProgile'
    ]),

    async handleRemovePGT () {
      try {
        this.loading = true
        await this.removePGTProgile(this.id)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
        this.$emit('refresh')
        this.onModalHidden()
      }
    },

    onModalHidden () {
      this.showed = false
      this.$emit('modal-hide')
    }
  }
})
